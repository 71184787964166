<template>
  <div :class="{'expiring': expirationComing && isExpirationColor}" class="agreement-card card redesign">
    <div class="card-head">
      <div class="icon-block">
        <icon icon-name="document-icon"></icon>
      </div>
      <div class="title-wrap-r">
        <h2 class="title">{{ agreement.productName }}</h2>
      </div>
    </div>

    <div class="card-body">
      <dl v-if="agreement" class="desc-list">
        <div class="agreement-wrap">
          <div>
            <dt class="desc-term">Дата создания страховки:</dt>
            <dd class="desc-detail">{{ dateGiven }}</dd>
          </div>
          <!-- Номера машин-->
          <div v-if="['1000', '1025', '2220', '2221'].includes(agreement.insrType)">
            <dt class="desc-term">Номер машины:</dt>
            <dd v-for="(item, index) in agreement.objects" :key="index" class="desc-detail">
              {{ item.license_plate }} <span v-if="agreement.objects.length > 1">{{ ',' }}</span>
            </dd>
          </div>
          <!-- Адрес -->
          <div v-if="['3011', '3012', '3017', '3019'].includes(agreement.insrType)">
            <dt class="desc-term">Адрес:</dt>
            <dd v-for="(item, index) in agreement.objects" :key="index" class="desc-detail">
              {{ item.street_name }} {{ item.blockNumber }}, {{ item.apartmentNumber }} <span
                v-if="agreement.objects.length > 1">{{ ',' }}</span>
            </dd>
          </div>
        </div>

        <div class="link-wrap">
          <router-link :to="routeToAgreement" class="gray-btn">{{ $t('goTariff') }}</router-link>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "AgreementCard",
  props: {
    agreement: {
      type: Object
    },
    type: {
      type: String
    },
    isActive: {
      type: Boolean
    },
    isExpirationColor: {
      type: Boolean
    },
    policies: null
  },
  computed: {
    policiesTypes() {
      return this.policies.map(item => item.insurance_type);
    },
    changedDate() {
      return moment(this.agreement.endDate.date).format("DD-MM-YYYY")
    },
    dateGiven() {
      if (this.agreement.dateGiven) {
        return moment(this.agreement.dateGiven).format("DD-MM-YYYY")
      } else {
        return moment(this.agreement.beginDate.date).format("DD-MM-YYYY")
      }
    },
    expirationComing() {
      if(this?.agreement?.insrType == '9100') {
        return false;
      }
      const endDate = new Date(this?.agreement?.endDate?.date);
      const today = new Date();
      const diffTime = endDate - today;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays <= 31;
    },
    routeToAgreement() {
      let product_id;
      let component;

      //Потом
      if (['1000'].includes(this.agreement.insrType)) {
        product_id = 1;
        component = 'ogpo-form'
        //обяза
      } else if (['2220', '2221'].includes(this.agreement.insrType)) {
        product_id = 2;
        component = 'casco-lite-form'
        //casco
      } else if (['3011', '3017'].includes(this.agreement.insrType)) {
        product_id = 3;
        component = 'flat-online-form'
        //Квартирный ответ online
      } else {
        product_id = 4;
        component = 'my-house-form'
        //house 3012
      }

      return { name: 'agreements.policy', params: { id: String(product_id), agreement: String(this.agreement.id) } }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "src/sass/variables";

.agreement-card {
  padding: 16px;

  .card-head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .card-body {
    .desc-list {
      margin-bottom: 20px;

      .desc-term, .desc-detail {
        font-weight: 600;
        font-size: 12px;
        display: inline-block;
        vertical-align: middle;

        &.green {
          color: $primary-color;
        }
      }

      .desc-term {
        width: 55%;

        &.width-unset {
          width: unset;
          margin-right: 4px;
        }
      }

      ul {
        &.left {
          padding-left: 16px;
        }

        li {
          font-weight: 600;
          font-size: 12px;
          width: 75%;

          &.dot {
            list-style: disc;
          }
        }
      }
    }

    .gray-btn {
      margin-top: 10px;
      text-transform: uppercase;

      &.extend {
        color: #FCB016;
      }
    }

  }
}

.expiring {
  background: rgba(255, 0, 0, 0.05);
  border: 1px solid rgba(255, 0, 0, 0.6);

  .gray-btn {
    background-color: #fff;
  }
}

.agreement-card.redesign {
  .card-head {
    justify-content: initial;
    align-items: initial;
    margin-bottom: 16px;

    .title-wrap-r {
      padding-top: 6px;
      padding-bottom: 22px;
      border-bottom: 1px solid #C8C7CC;
      margin-left: 16px;
      width: 100%;

      .title {
        font-family: 'Manrope';
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #071222;
        margin-bottom: 0px;
      }
    }
  }

  .card-body {
    .desc-list {
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 0px;
      /*flex-direction: column;*/
      align-items: flex-start;

      .agreement-wrap {
        width: 100%;
      }

      .desc-term {
        color: #99A3B3;
        font-weight: 500;
      }

      .desc-detail {
        color: #566681;
        font-weight: 700;

        &.error {
          color: #FF0000;
        }
      }

      .link-wrap .gray-btn {
        margin-top: 0px;
      }
    }
  }

}


</style>
