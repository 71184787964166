import { render, staticRenderFns } from "./AgreementCardLife.vue?vue&type=template&id=81677c94&scoped=true&"
import script from "./AgreementCardLife.vue?vue&type=script&lang=js&"
export * from "./AgreementCardLife.vue?vue&type=script&lang=js&"
import style0 from "./AgreementCardLife.vue?vue&type=style&index=0&id=81677c94&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81677c94",
  null
  
)

export default component.exports