<template>
  <div class="px-3">
    <ul class="tabs">
      <router-link
          v-for="(item, index) in tabs"
          :key="index"
          :to="item.route"
          v-slot="{ href, route, navigate, isActive, isExactActive }"
      >
        <li
            :class="[isActive && 'active', isExactActive && 'router-link-exact-active', 'tab']"
        >
          <a :href="href" @click="navigate">{{ item.name }}</a>
        </li>
      </router-link>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        {name: this.$i18n.t('effectiveAgreements'), route: 'agreements'},
        {name: this.$i18n.t('expiredAgreements'), route: 'archive-agreements'},
        {name: this.$i18n.t('myMedCards'), route: 'med-cards'}
      ],
    }
  }
}
</script>


<style lang="scss">
@import "src/sass/variables";

.tabs {
  background: #FFFFFF;
  border: 1px solid #F1F2F6;
  border-radius: 12px;
  width: 100%;
  display: flex;
  padding: 2px 3px;
  margin-bottom: 20px;

  .tab {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;

    a {
      font-weight: bold;
      font-size: 12px;
    }

    &.active {
      a{
        background: $primary-color;
        box-shadow: 0 12px 16px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        color: #fff;
      }
    }
  }
}
</style>
